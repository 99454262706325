<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-12">
        <form class="form">
          <div class="card card-custom card-sticky">
            <div class="card-header" style="">
              <div class="card-title">
                <h3 class="card-label">
                  {{ $t("COSTSHEET.EDIT_COSTSHEET") }}: {{ costSheets.name }}
                </h3>
              </div>
              <CardToolbar
                @submitEvent="onSubmit"
                back-url="/cost-sheets/list"
                buttonText="Update Cost Sheet"
              ></CardToolbar>
            </div>
            <div class="card-body p-0">
              <div
                class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
              >
                <div class="col-xl-12 col-xxl-10">
                  <div class="row justify-content-center">
                    <div class="col-xl-9">
                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.NAME") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <input
                            id="name"
                            class="form-control form-control-lg"
                            v-model="costSheets.name"
                            name="name"
                            type="text"
                            :placeholder="$t('GENERAL.NAME')"
                          />
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.CONSTRUCTION_SITE") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <v-select
                            class="form-control form-control--construction-select"
                            :placeholder="$t('GENERAL.CONSTRUCTION_SITE')"
                            v-model="costSheets.construction"
                            :options="constructions"
                            :reduce="(construction) => construction['@id']"
                            label="name"
                          >
                          </v-select>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("GENERAL.AMOUNT") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <div class="input-group">
                            <input
                              class="form-control"
                              v-model="costSheets.amount"
                              name="amount"
                              type="text"
                              placeholder="€"
                            />
                            <div class="input-group-prepend">
                              <span class="input-group-text"
                                ><i class="la la-euro"></i
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("COSTSHEET.COST_DESCRIPTION") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <textarea
                            class="form-control"
                            v-model="costSheets.note"
                            rows="4"
                            :placeholder="$t('COSTSHEET.COST_DESCRIPTION')"
                          ></textarea>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("COSTSHEET.BILLING_DATE") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <vc-date-picker
                            v-model="costSheets.billingDate"
                            :masks="masks"
                          >
                            <template v-slot="{ inputValue, togglePopover }">
                              <div class="input-group" @click="togglePopover()">
                                <input
                                  class="form-control"
                                  :value="inputValue"
                                  name="billingDate"
                                  type="text"
                                />
                                <div class="input-group-prepend cursor-pointer">
                                  <span class="input-group-text"
                                    ><i class="la la-calendar"></i
                                  ></span>
                                </div>
                              </div>
                            </template>
                          </vc-date-picker>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("COSTSHEET.FROM_DATE") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <vc-date-picker
                            v-model="costSheets.fromDate"
                            :masks="masks"
                          >
                            <template v-slot="{ inputValue, togglePopover }">
                              <div class="input-group" @click="togglePopover()">
                                <input
                                  class="form-control"
                                  :value="inputValue"
                                  name="fromDate"
                                  type="text"
                                />
                                <div class="input-group-prepend cursor-pointer">
                                  <span class="input-group-text"
                                    ><i class="la la-calendar"></i
                                  ></span>
                                </div>
                              </div>
                            </template>
                          </vc-date-picker>
                        </div>
                      </div>

                      <div class="form-group row">
                        <label class="col-xl-3 col-lg-3 col-form-label"
                          >{{ $t("COSTSHEET.TO_DATE") }}:</label
                        >
                        <div class="col-lg-9 col-xl-9">
                          <vc-date-picker
                            v-model="costSheets.toDate"
                            :masks="masks"
                          >
                            <template v-slot="{ inputValue, togglePopover }">
                              <div class="input-group" @click="togglePopover()">
                                <input
                                  class="form-control"
                                  :value="inputValue"
                                  name="toDate"
                                  type="text"
                                />
                                <div class="input-group-prepend cursor-pointer">
                                  <span class="input-group-text"
                                    ><i class="la la-calendar"></i
                                  ></span>
                                </div>
                              </div>
                            </template>
                          </vc-date-picker>
                        </div>
                      </div>

                      <div class="separator separator-dashed my-10"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CardToolbar from "@/view/content/components/card-toolbar/CardToolbar.vue";
import ApiService from "@/core/services/api.service";
import vSelect from "vue-select";

export default {
  name: "EditCostSheets",
  components: { CardToolbar, vSelect },
  data() {
    return {
      costSheets: {
        name: null,
        amount: null,
        note: null,
        billingDate: null,
        fromDate: null,
        toDate: null,
        isVariable: false,
        construction: null,
      },
      isCostSheetLoaded: false,
      constructions: [],
      isConstructionsLoaded: false,
      masks: {
        input: "DD.MM:YYYY.",
      },
      appPath: process.env.VUE_APP_ROUTE_API_URL,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("COSTSHEET.ALL_COST_SHEETS"),
        route: "/cost-sheets/list",
      },
      { title: this.$t("COSTSHEET.EDIT_COSTSHEET") },
    ]);

    ApiService.get("cost_sheets/", this.$route.params.id).then(({ data }) => {
      this.costSheets.name = data.name;
      this.costSheets.amount = data.amount;
      this.costSheets.note = data.note;
      this.costSheets.billingDate = data.billingDate;
      this.costSheets.fromDate = data.fromDate;
      this.costSheets.toDate = data.toDate;
      this.costSheets.isVariable = data.isVariable;
      this.costSheets.construction = data.construction;
      this.isCostSheetLoaded = true;

      ApiService.get("constructions").then(({ data }) => {
        this.constructions = data["hydra:member"];
        this.isConstructionsLoaded = true;
      });
    });
  },
  methods: {
    onSubmit(type) {
      ApiService.update("cost_sheets", this.$route.params.id, this.costSheets)
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            title: this.$t("COSTSHEET.COSTSHEET_UPDATED"),
          });
          if (type === "1") {
            setTimeout(() => {
              this.$router.push({ name: "cost-sheets-list" });
            }, 1000);
          }
        })
        .catch((error) => {
          this.$notify({
            group: "notification",
            type: "error",
            title: error,
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
